<template>
  <div id="datalabels">
    <el-form @submit.native.prevent ref="form">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Data Labels" name="0">
          <el-form-item label="Show">
            <el-switch
              @change="updateChart"
              inactive-color="#24272b"
              v-model="dataLabels.enabled"
            ></el-switch>
          </el-form-item>

          <h4>Labels Left Offset</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dataLabels.offsetX"
              :max="20"
              :min="-20"
              show-input
            ></el-slider>
          </div>

          <h4>Labels Top Offset</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dataLabels.offsetY"
              :max="20"
              :min="-20"
              show-input
            ></el-slider>
          </div>

          <h4>Font Size</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dataLabels.style.fontSize"
              :min="2"
              :max="80"
              show-input
            ></el-slider>
          </div>

          <h4>Font Weight</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dataLabels.style.fontWeight"
              :min="100"
              :step="100"
              :max="900"
            ></el-slider>
          </div>

          <el-form-item class="custom-colors-picker" label="Colors">
            <extended-el-color-picker
              v-for="(color, index) in dataLabels.style.colors"
              :key="index"
              @change="
                (val) => {
                  updateDataLabelsColors(val, index);
                }
              "
              :value="color"
            ></extended-el-color-picker>
            <el-button @click="appendDataLabelColor" size="mini">
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              @click="clearAllDataLabelsColors"
              size="mini"
              title="Delete All Colors"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>

          <el-collapse v-if="chartType !== 'heatmap'" class="mt3">
            <el-collapse-item title="Background">
              <el-form-item label="Enable">
                <el-switch
                  @change="updateChart"
                  inactive-color="#24272b"
                  v-model="dataLabels.background.enabled"
                ></el-switch>
              </el-form-item>

              <div v-if="dataLabels.background.enabled">
                <el-form-item label="ForeColor">
                  <extended-el-color-picker
                    @change="updateChart"
                    v-model="dataLabels.background.foreColor"
                  ></extended-el-color-picker>
                </el-form-item>

                <el-form-item label="Border Color">
                  <extended-el-color-picker
                    @change="updateChart"
                    v-model="dataLabels.background.borderColor"
                  ></extended-el-color-picker>
                </el-form-item>

                <h4>Border Radius</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="dataLabels.background.borderRadius"
                    :min="0"
                    :max="20"
                  ></el-slider>
                </div>

                <h4>Border Width</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="dataLabels.background.borderWidth"
                    :min="0"
                    :max="20"
                  ></el-slider>
                </div>

                <h4>Opacity</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="dataLabels.background.opacity"
                    :min="0"
                    :max="1"
                    :step="0.1"
                  ></el-slider>
                </div>

                <h4>Padding</h4>
                <div class="block">
                  <el-slider
                    @change="updateChart"
                    v-model="dataLabels.background.padding"
                    :min="0"
                    :max="20"
                  ></el-slider>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>

          <el-collapse class="mt3">
            <el-collapse-item title="Text DropShadow">
              <el-form-item label="Enable">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.enabled"
                ></el-switch>
              </el-form-item>

              <h4>Blur</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.blur"
                  :max="30"
                ></el-slider>
              </div>

              <h4>Offset X</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.left"
                  :min="-30"
                  :max="30"
                ></el-slider>
              </div>

              <h4>Offset Y</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.top"
                  :min="-30"
                  :max="30"
                ></el-slider>
              </div>

              <el-form-item label="Shadow Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.color"
                ></extended-el-color-picker>
              </el-form-item>

              <h4>Shadow Opacity</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="dataLabels.dropShadow.opacity"
                  :step="0.05"
                  :min="0"
                  :max="1"
                ></el-slider>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
        <el-collapse-item
          v-if="
            chartType === 'line' ||
              chartType === 'area' ||
              chartType === 'scatter' ||
              chartType === 'bubble' ||
              chartType === 'radar'
          "
          title="Markers"
          name="1"
        >
          <h4>Size</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="markers.size"
              :min="0"
              :max="25"
            ></el-slider>
          </div>

          <el-form-item
            label="Marker Colors"
            :class="{ 'custom-colors-picker': true }"
          >
            <extended-el-color-picker
              v-for="(color, index) in markers.colors"
              :key="index"
              @change="
                (val) => {
                  updateMarkerColors(val, index);
                }
              "
              :value="color"
            ></extended-el-color-picker>
            <el-button @click="appendMarkerColor" size="mini" class="ml2">
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              @click="clearAllMarkerColors"
              size="mini"
              title="Delete All Colors"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>

          <h4>Opacity</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="markers.fillOpacity"
              :max="1"
              :min="0"
              :step="0.1"
              show-input
            ></el-slider>
          </div>

          <h4>Hover Size</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="markers.hover.size"
              :max="25"
              :min="0"
              show-input
            ></el-slider>
          </div>

          <el-form-item class="mt2" label="Marker Shape">
            <el-radio-group
              @change="updateChart"
              size="small"
              v-model="markers.shape"
            >
              <el-radio-button label="circle">circle</el-radio-button>
              <el-radio-button label="square">square</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <div v-if="markers.shape === 'square'">
            <h4>Border Radius</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="markers.radius"
                :max="50"
                :min="0"
                show-input
              ></el-slider>
            </div>
          </div>

          <div>
            <h4>Border Width</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="markers.strokeWidth"
                :max="10"
                :min="0"
                show-input
              ></el-slider>
            </div>
          </div>

          <el-form-item class="mt2" label="Border Color">
            <extended-el-color-picker
              @change="updateChart"
              v-model="markers.strokeColors"
            ></extended-el-color-picker>
          </el-form-item>

          <h4>Border Opacity</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="markers.strokeOpacity"
              :max="1"
              :min="0"
              :step="0.1"
              show-input
            ></el-slider>
          </div>

          <h4>Border Dash Array</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="markers.strokeDashArray"
              :max="30"
              :min="0"
              show-input
            ></el-slider>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["0", "1"],
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 12,
          fontWeight: 700,
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      markers: {
        size: 0,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        hover: {
          size: undefined,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      this.setNewOptions(newProp);
    },
  },
  mounted: function() {
    const opts = this.getChartConfig().w.config;
    this.setNewOptions(opts);
  },
  methods: {
    setNewOptions(opts) {
      const d = opts.dataLabels;
      const m = opts.markers;
      this.dataLabels = {
        enabled: d.enabled,
        textAnchor: d.textAnchor,
        offsetX: d.offsetX,
        offsetY: d.offsetY,
        style: {
          fontSize: d.style.fontSize,
          fontWeight: d.style.fontWeight,
          colors: d.style.colors,
        },
        background: {
          enabled: d.background.enabled,
          foreColor: d.background.foreColor,
          borderRadius: d.background.borderRadius,
          padding: d.background.padding,
          opacity: d.background.opacity,
          borderWidth: d.background.borderWidth,
          borderColor: d.background.borderColor,
        },
        dropShadow: {
          enabled: d.dropShadow.enabled,
          top: d.dropShadow.top,
          left: d.dropShadow.left,
          blur: d.dropShadow.blur,
          color: d.dropShadow.color,
          opacity: d.dropShadow.opacity,
        },
      };
      this.markers = {
        size: m.size,
        colors: m.colors,
        strokeColors: m.strokeColors,
        strokeWidth: m.strokeWidth,
        strokeOpacity: m.strokeOpacity,
        strokeDashArray: m.strokeDashArray,
        fillOpacity: m.fillOpacity,
        shape: m.shape,
        radius: m.radius,
        offsetX: m.offsetX,
        offsetY: m.offsetY,
        hover: {
          size: m.hover.size,
        },
      };
    },

    updateDataLabelsColors(color, index) {
      Vue.set(this.dataLabels.style.colors, index, color);
      this.updateChart();
    },
    appendDataLabelColor() {
      if (!this.dataLabels.style.colors) {
        this.dataLabels.style.colors = [];
      }
      this.dataLabels.style.colors.push("#f1f2f3");
      this.updateChart();
    },
    clearAllDataLabelsColors() {
      this.dataLabels.style.colors = undefined;
      this.updateChart();
    },

    updateMarkerColors(color, index) {
      Vue.set(this.markers.colors, index, color);
      this.updateChart();
    },
    appendMarkerColor() {
      if (!this.markers.colors) {
        this.markers.colors = [];
      }
      this.markers.colors.push("#fff");
      this.updateChart();
    },
    clearAllMarkerColors: function() {
      this.markers.colors = undefined;
      this.updateChart();
    },
    updateChart: function() {
      const d = this.dataLabels;
      const m = this.markers;

      this.updateChartOptions({
        dataLabels: {
          enabled: d.enabled,
          textAnchor: d.textAnchor,
          offsetX: d.offsetX,
          offsetY: d.offsetY,
          style: {
            fontSize: d.style.fontSize,
            fontWeight: d.style.fontWeight,
            colors: d.style.colors,
          },
          background: {
            enabled: d.background.enabled,
            foreColor: d.background.foreColor,
            borderRadius: d.background.borderRadius,
            padding: d.background.padding,
            opacity: d.background.opacity,
            borderWidth: d.background.borderWidth,
            borderColor: d.background.borderColor,
          },
          dropShadow: {
            enabled: d.dropShadow.enabled,
            top: d.dropShadow.top,
            left: d.dropShadow.left,
            blur: d.dropShadow.blur,
            color: d.dropShadow.color,
            opacity: d.dropShadow.opacity,
          },
        },
        markers: {
          size: m.size,
          colors: m.colors,
          strokeColors: m.strokeColors,
          strokeWidth: m.strokeWidth,
          strokeOpacity: m.strokeOpacity,
          strokeDashArray: m.strokeDashArray,
          fillOpacity: m.fillOpacity,
          shape: m.shape,
          radius: m.radius,
          offsetX: m.offsetX,
          offsetY: m.offsetY,
          hover: {
            size: m.hover.size,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
